import { ComponentType } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { displayPrice } from 'utils'
import { MdAdd, MdRemove } from 'react-icons/all'
import {
    decrementItemQuantity,
    incrementItemQuantity,
    selectOrder
} from 'features/order/orderSlice'
import { Box, Grid } from '@mui/material'
import { useTranslation } from 'i18n'
import { Button } from 'components/Button'
import { Heading } from 'components/Heading'

const QuantityButton: ComponentType<{
    primary: boolean
    onClick: () => void
}> = ({ primary, onClick, children }) => {
    return (
        <Button
            color={primary ? 'primary' : 'secondary'}
            style={{ fontSize: 18 }}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

export const AvailableItemList = () => {
    const t = useTranslation()
    const order = useAppSelector(selectOrder)
    const dispatch = useAppDispatch()
    const hasItems = order.items.length > 0
    if (!hasItems) return null
    return (
        <Box>
            <Heading size={2}>{t.availableItems}</Heading>
            {order.items.map((item, idx) => (
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        mb: idx === order.items.length - 1 ? 0 : 2
                    }}
                    key={item.id}
                >
                    <Grid item xs={4}>
                        <Heading size={3}>{item.title}</Heading>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {displayPrice(item.price)}
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <QuantityButton
                            primary
                            onClick={() =>
                                dispatch(incrementItemQuantity(item.id))
                            }
                        >
                            <MdAdd />
                        </QuantityButton>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <QuantityButton
                            primary={false}
                            onClick={() =>
                                dispatch(decrementItemQuantity(item.id))
                            }
                        >
                            <MdRemove />
                        </QuantityButton>
                    </Grid>
                </Grid>
            ))}
        </Box>
    )
}
