import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#23CE6B'
        },
        secondary: {
            main: '#F06543'
        }
    },
    typography: {
        h1: {
            fontSize: '1.6rem'
        },
        h2: {
            fontSize: '1.3rem',
            fontWeight: 700
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 700
        }
    }
})
