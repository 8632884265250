import { ComponentType } from 'react'
import { useAppSelector } from 'app/hooks'
import { useTranslation } from 'i18n'
import { displayPrice } from 'utils'
import { selectOrder } from 'features/order/orderSlice'
import { Box, Divider, Grid } from '@mui/material'
import { Heading } from 'components/Heading'

export const OrderList: ComponentType = () => {
    const t = useTranslation()
    const order = useAppSelector(selectOrder)
    const orderedItems = order.items.filter(i => i.quantity > 0)
    if (orderedItems.length === 0) return null
    return (
        <Box>
            <Heading size={2}>{t.order}</Heading>
            {orderedItems.map((item, idx) => (
                <Grid
                    container
                    key={item.id}
                    sx={{ mb: idx === orderedItems.length - 1 ? 0 : 1 }}
                >
                    <Grid item xs={4}>
                        <Heading size={3}>{item.title}</Heading>
                    </Grid>

                    <Grid
                        item
                        xs={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <span>{displayPrice(item.price)}</span>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <span>x {item.quantity}</span>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <b>{displayPrice(item.totalPrice)}</b>
                    </Grid>
                </Grid>
            ))}
            <Divider sx={{ my: 1 }} />
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <Heading size={3}>{t.total}:</Heading>
                </Grid>
                <Grid
                    item
                    xs={10}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <b>{displayPrice(order.total)}</b>
                </Grid>
            </Grid>
        </Box>
    )
}
