export const translation = {
    item: {
        title: 'Názov',
        price: 'Cena',
        addItem: 'Pridať',
        error: {
            alreadyTaken: 'Položka už existuje'
        }
    },
    about: {
        title: 'Čo je ORDERITO?',
        par1: 'ORDERITO je webová aplikácia, pomocou ktorej si môžes jednoducho zapisovať čo si si objednal, či už je to pitie alebo jedlo aby keď príjde na platenie, si vedel čo máš zaplatiť a nemusíš sa snažiť si spomenúť, čo si mal. A ako bonus, taktieť vidíš čo si vypil a možno vďaka tomu si uvedomíš, že si treba dať pauzu.',
        par2: 'Plánujeme pridať aj počítadlo promile, na rátanie a varovanie pred intoxikovaním.'
    },
    availableItems: 'Dostupné položky',
    order: 'Objednávka',
    reset: 'Reset',
    total: 'Spolu',
    pwaPrompt: 'Pridaj si appku na plochu cez'
}
