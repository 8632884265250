import { ComponentType } from 'react'
import { AddItem } from 'features/order/components/AddItem'
import { reset, selectOrder } from 'features/order/orderSlice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { OrderList } from 'features/order/components/OrderList'
import { useTranslation } from 'i18n'
import { Grid } from '@mui/material'
import { AvailableItemList } from 'features/order/components/AvailableItemList'
import { Button } from 'components/Button'

export const Home: ComponentType = () => {
    const order = useAppSelector(selectOrder)
    const hasItems = order.items.length > 0
    const dispatch = useAppDispatch()
    const t = useTranslation()
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <AddItem />
            </Grid>

            <Grid item xs={12}>
                <AvailableItemList />
            </Grid>

            <Grid item xs={12}>
                <OrderList />
            </Grid>
            {hasItems && (
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Button
                        color="secondary"
                        style={{ width: '50%' }}
                        onClick={() => dispatch(reset())}
                    >
                        {t.reset}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}
