import React from 'react'
import { Header } from 'components/Header'
import { Route, Routes } from 'react-router-dom'
import { pages } from 'pages/pages'
import { Home } from 'pages/Home'
import { About } from 'pages/About'
import Container from '@mui/material/Container'
import { Footer } from 'components/Footer'
import { PWAPrompt } from 'components/PWAPrompt'

function App() {
    return (
        <div className="app">
            <Header />
            <Container sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <Routes>
                    <Route path={pages.home} element={<Home />} />
                    <Route path={pages.about} element={<About />} />
                </Routes>
            </Container>
            <Footer />
            <PWAPrompt />
        </div>
    )
}

export default App
