import { ComponentType } from 'react'
import { appName } from 'utils/contants'
import { AppBar, Button, Toolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { pages } from 'pages/pages'
import { MdInfoOutline } from 'react-icons/all'

export const Header: ComponentType = () => {
    const navigate = useNavigate()
    return (
        <AppBar
            position="sticky"
            sx={{ color: 'white', textTransform: 'uppercase' }}
        >
            <Toolbar>
                <Typography
                    variant="h1"
                    sx={{
                        flexGrow: 1,
                        fontWeight: '700',
                        letterSpacing: '1px'
                    }}
                    onClick={() => navigate(pages.home)}
                >
                    {appName}
                </Typography>
                <Button
                    color="inherit"
                    onClick={() => navigate(pages.about)}
                    sx={{ fontSize: 26 }}
                >
                    <MdInfoOutline />
                </Button>
            </Toolbar>
        </AppBar>
    )
}
