import { ComponentType } from 'react'

export const Footer: ComponentType = () => {
    return (
        <div className="footer">
            <a
                href="https://www.pgs.sk/?utm_source=5d9a3a2dde48e&utm_medium=dognet&amp;a_aid=5d9a3a2dde48e&amp;a_bid=c0e54cc2"
                rel="nofollow"
                target="_top"
            >
                <img
                    src="//login.dognet.sk/accounts/default1/fb27g82d/c0e54cc2.jpg"
                    alt=""
                    title=""
                />
            </a>
        </div>
    )
}
