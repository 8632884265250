import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { NewItem, Order } from 'types'
import { generateId, roundTo2Decimals } from 'utils'
import { orderTotal } from 'features/order/utils'

export type OrderState = Order & {
    error: string | null
}

export const initialState: OrderState = {
    items: [],
    total: 0,
    error: null
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<NewItem>) => {
            const valid = !state.items.some(
                i => i.title === action.payload.title
            )
            state.error = valid ? null : 'Already added'
            if (valid) {
                state.items.push({
                    ...action.payload,
                    price: Number(action.payload.price.toFixed(2)),
                    id: generateId(),
                    quantity: 0,
                    totalPrice: 0
                })
            }
        },

        incrementItemQuantity: (state, action: PayloadAction<string>) => {
            const item = state.items.find(i => i.id === action.payload)
            if (item) {
                item.quantity++
                item.totalPrice = roundTo2Decimals(item.quantity * item.price)
                state.total = orderTotal(state)
            }
        },

        decrementItemQuantity: (state, action: PayloadAction<string>) => {
            const item = state.items.find(i => i.id === action.payload)
            if (item && item.quantity > 0) {
                item.quantity--
                item.totalPrice = roundTo2Decimals(item.quantity * item.price)
                state.total = orderTotal(state)
            }
        },

        reset: state => initialState
    }
})

export const { addItem, incrementItemQuantity, decrementItemQuantity, reset } =
    orderSlice.actions

export const selectOrder = (state: RootState) => state.order

export default orderSlice.reducer
