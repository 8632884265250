import { ComponentType, useState } from 'react'
import { IconButton, Snackbar, SnackbarContent, useTheme } from '@mui/material'
import { MdClose } from 'react-icons/all'
import { useTranslation } from 'i18n'
import iosShare from 'img/share.png'

// Detects if device is on iOS
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
}
// Detects if device is in standalone mode
const isInStandaloneMode = () =>
    // @ts-ignore
    'standalone' in window.navigator && window.navigator.standalone

export const PWAPrompt: ComponentType = () => {
    const t = useTranslation()
    const th = useTheme()
    const [showButton, setShowButton] = useState(false)

    setTimeout(() => {
        if (isIos() && !isInStandaloneMode()) {
            setShowButton(true)
        }
    }, 15000)
    const closePrompt = () => setShowButton(false)
    return (
        <Snackbar
            open={showButton}
            autoHideDuration={6000}
            onClose={closePrompt}
        >
            <SnackbarContent
                message={
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        {t.pwaPrompt}{' '}
                        <img
                            src={iosShare}
                            width={18}
                            style={{ marginLeft: '12px' }}
                            alt="Pwa"
                        />
                    </div>
                }
                sx={{ background: th.palette.secondary.main }}
                action={
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={closePrompt}
                        >
                            <MdClose fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </Snackbar>
    )
}
