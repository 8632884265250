import { ComponentType } from 'react'
import { Button as MUIButton } from '@mui/material'

type ButtonProps = {
    onClick: () => void
    disabled?: boolean
    color: 'primary' | 'secondary'
    style?: Record<string, any>
}

export const Button: ComponentType<ButtonProps> = ({
    onClick,
    disabled,
    color,
    children,
    style
}) => {
    return (
        <MUIButton
            variant="contained"
            color={color}
            onClick={onClick}
            disabled={disabled}
            sx={{ color: 'white', ...style }}
        >
            {children}
        </MUIButton>
    )
}
