import { ComponentType } from 'react'
import TextField from '@mui/material/TextField'

type InputProps = {
    label?: string
    placeholder?: string
    value: string | number
    name: string
    type?: 'text' | 'number'
    hint?: string
    onChange: (v: string) => void
}

export const Input: ComponentType<InputProps> = ({
    label,
    value,
    name,
    onChange,
    type = 'text',
    placeholder,
    hint
}) => {
    return (
        <TextField
            id={name}
            label={label}
            variant="standard"
            helperText={hint}
            placeholder={placeholder}
            value={value.toString()}
            onChange={e => onChange(e.target.value)}
            type={type}
        />
    )
}
