import { ComponentType, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addItem, selectOrder } from 'features/order/orderSlice'
import { Input } from 'components/form/Input'
import { useTranslation } from 'i18n'
import { Alert, Grid, styled } from '@mui/material'
import { Button } from 'components/Button'

const MyInput = styled(Input)(({ theme }) => ({
    '& .MuiInput-underline:after': {
        borderBottomColor: 'red'
    }
}))

const defState = { title: '', price: '' }

export const AddItem: ComponentType = () => {
    const dispatch = useAppDispatch()
    const t = useTranslation()
    const order = useAppSelector(selectOrder)
    const [item, setItem] = useState(defState)
    const [error, setError] = useState('')
    const valid = item.title.length > 0 && Number(item.price) > 0
    const addNewItem = () => {
        if (order.items.find(i => i.title === item.title)) {
            setError(t.item.error.alreadyTaken)
        } else {
            setError('')
            dispatch(addItem({ ...item, price: Number(item.price) }))
            setItem(defState)
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <MyInput
                    label={t.item.title}
                    value={item.title}
                    name="title"
                    onChange={v => {
                        setItem(prevState => ({ ...prevState, title: v }))
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <MyInput
                    label={t.item.price}
                    value={item.price}
                    name="price"
                    type="number"
                    onChange={v =>
                        setItem(prevState => ({
                            ...prevState,
                            price: v
                        }))
                    }
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ justifyContent: 'center', display: 'flex' }}
            >
                <Button
                    color="primary"
                    onClick={addNewItem}
                    disabled={!valid}
                    style={{ width: '50%' }}
                >
                    {t.item.addItem}
                </Button>
            </Grid>

            {error && <Alert>{error}</Alert>}
        </Grid>
    )
}
