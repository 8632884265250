import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import orderReducer from 'features/order/orderSlice'
import { debounce } from 'debounce'

const KEY = 'app-state'
export function loadState() {
    try {
        const serializedState = localStorage.getItem(KEY)
        if (!serializedState) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export function saveState(state: any) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(KEY, serializedState)
    } catch (e) {
        // Ignore
    }
}

export const store = configureStore({
    reducer: {
        order: orderReducer
    },
    preloadedState: loadState()
})

store.subscribe(
    // we use debounce to save the state once each 800ms
    // for better performances in case multiple changes occur in a short time
    debounce(() => {
        saveState(store.getState())
    }, 800)
)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
