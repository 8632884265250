import { ComponentType } from 'react'
import { Typography } from '@mui/material'

type HeadingProps = {
    size: 1 | 2 | 3
    style?: Record<string, any>
}

export const Heading: ComponentType<HeadingProps> = ({
    size,
    children,
    style
}) => {
    const variant = `h${size}` as 'h1' | 'h2' | 'h3'

    return (
        <Typography variant={variant} sx={{ mb: size === 2 ? 1 : 0, ...style }}>
            {children}
        </Typography>
    )
}
