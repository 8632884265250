import { ComponentType } from 'react'
import { Heading } from 'components/Heading'
import { useTranslation } from 'i18n'
import { Typography } from '@mui/material'

export const About: ComponentType = () => {
    const t = useTranslation()
    return (
        <div>
            <Heading size={2}>{t.about.title}</Heading>
            <Typography variant="body1" sx={{ mb: 1 }}>
                {t.about.par1}
            </Typography>
            <Typography variant="body1">{t.about.par2}</Typography>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kSs2Iq-SjDU?clip=UgkxOU2WDyJmAbUy7DEydWbLdZZDPWoIcotJ&clipt=EP3wFxj2oxg&autoplay=1"
                title="YouTube video player"
                //@ts-ignore
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        </div>
    )
}
